<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="group_1 flex-col">
        <header>
          <van-nav-bar
                  title="订单详情"
                  left-text=""
                  left-arrow
                  @click-left="onClickLeft"
          />
        </header>
        <div class="block_4 flex-row">
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/7266e787ace09937859e41356c3d2088.png"
          />
          <span class="text_6">{{orderDeatil.orderStatusName}}</span>
          <span class="text_7">{{orderDeatil.tableCode}}号桌</span>
        </div>
      </div>
      <div class="group_2 flex-col">

        <div class="group_4 flex-col">

          <div class="box_1 flex-row" v-for="(item, index) in orderDeatil.sysOrderDetails" :key="index">
            <div class="block_5 flex-col">
              <img :src="item.smallImagePath" alt="" style="height: 6rem;width: 8rem">
            </div>
            <div class="block_6 flex-col justify-between">
              <span class="text_12">{{ item.productName }}</span>
              <div class="text-wrapper_3">
                <span class="text_13">¥</span>
                <span class="text_14">{{ item.productPrice }}</span>
              </div>
            </div>
            <span class="text_15">X{{ item.productNum }}</span>
          </div>

          <div class="box_2 flex-col"></div>
          <div class="box_3 flex-row">
            <span class="text_16">共{{orderNum}}件</span>
            <!--<div>
              <span class="text_161">继续点餐</span>
            </div>-->
            <div class="text-wrapper_61" @click="saveOrderInfo(orderDeatil.shopId,orderDeatil.tableCode,orderDeatil.orderCode)">
              <span class="text_151">继续点餐</span>
            </div>
            <span class="text_17">合计</span>
            <div class="text-wrapper_4">
              <span class="text_18">¥</span>
              <span class="text_19">{{orderDeatil.orderPrice}}</span>
            </div>
          </div>
          <div class="box_3 flex-row">
            <span class="text_16">订单编号&nbsp;&nbsp;{{orderDeatil.orderCode}}</span>
          </div>
          <div class="box_3 flex-row">
            <span class="text_16">下单时间&nbsp;&nbsp;{{orderDeatil.createTime}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import {queryOrderDetail} from "@/api/order";
  import axios from 'axios';
export default {
  data() {
    return {
      baseURL: axios.defaults.baseURL,
      orderDeatil: {
        sysOrderDetails:[],
      },
      orderNum:0
    };
  },
  created() {
    const orderCode = this.$route.query.orderCode;
    if(orderCode){
      this.queryOrderDetail(orderCode);
    }
  },
  methods: {
    async queryOrderDetail(code) {
      queryOrderDetail(code).then(response => {
        if(response.code==200){
          this.orderDeatil = response.data;
          this.orderDeatil.sysOrderDetails.forEach((item) => {
            if(item.smallImagePath){
              item.smallImagePath = this.baseURL + item.smallImagePath;
            }else{
              item.smallImagePath = require('./assets/img/1713621686447.jpg')
            }
          })
          this.orderNum = this.orderDeatil.sysOrderDetails.length
        }else{
          this.$model({
            show: true,
            title: "提示",
            content: response.msg,
            confirmButton: false,
            cancelButton: true
          });
        }
      });
    },
    saveOrderInfo(shopId,tableCode,orderCode){
      this.$router.push({ name: 'enterMall', query: { shopId: shopId,tableNumber: tableCode,orderCode: orderCode}});
    },
    onClickLeft(){
      //this.$router.go(-1)
      this.$router.push('/home')
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />